body {
    overflow-y: scroll;
}

body::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.wrap-text {
    word-wrap: break-word;
}

.backgroundColor {
    min-height: 100vh;
    background: #9E2A2A;
}

.color-font-red {
    color: #9E2A2A;
}

.button-online {
    width: 50%;
}

.card-img {
    object-fit: cover;
    width: 100%;
    max-height: 200px;
}

.white-font {
    color: #FFFFFF;
}

.open-menu {
    position: absolute;
    right: 12px;
    bottom: 12px;
    background: #FFFFFF;
    padding: 2px 8px;
    line-height: normal;
    color: #9E2A2A;
}

.open-menu:hover {
    color: #FFFFFF;
    background: #9E2A2A;
}

.button-sosmed {
    width: 100%;
    color: #FFFFFF;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
}

.mt-n1 {
    margin-top: -0.35rem !important;
}

.mt-n2 {
    margin-top: -0.5rem !important;
}

.button-outlet-big {
    width: 100%;
    color: #9E2A2A;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #FFFFFF;
}

.button-outlet-big:hover {
    color: #9E2A2A;
    background: #FFFFFF;
}

.whatsapp {
    background: #25D366;
}

.whatsapp:hover {
    background: #25D366;
    color: #f0f0f0;
}

.instagram {
    background: #f0f0f0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.instagram:hover {
    color: #FFFFFF;
}

.footer {
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.50);
}

.indexs {
    z-index: 1;
}

.image-kirin-kanan {
    width: 297px;
    height: 223px;
    position: absolute;
    /* top: 50px;
    right: 475px; */
    top: 400px;
    right: 0px;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: contain;
}

.modal-header {
    display: inline !important;
}

.image-kirin-kiri {
    width: 297px;
    height: 223px;
    position: absolute;
    /* top: 50px;
    right: 475px; */
    top: 1200px;
    left: -10px;
    transform: scaleX(-1);
    z-index: 0;
    background-repeat: no-repeat;
    background-size: contain;
}

@media (min-width: 320px) {

    .button-sosmed {
        width: 86%;
    }

}

@media (min-width: 768px) {

    .button-sosmed {
        width: 94%;
    }

}

@media (min-width: 1024px) {

    .button-sosmed {
        width: 86%;
    }

}

.button-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.button-icon-big {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
}

.button-text {
    color: #FFFFFF;
    font-weight: 600;
}

.Transparan {
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    opacity: 50%;
    z-index: 0;
}

.carousel {
    width: 100%;
    display: flex;
    overflow-x: scroll !important;
}

.carousel::-webkit-scrollbar {
    display: none;
}

.carousel {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.LazyLoad {
    opacity: 0;
    transition: all 1s ease-in-out;
}

.is-visible {
    opacity: 1;
}

.item-menu {
    max-width: 136px;
}

.image img {
    max-width: 136px;
    max-height: 136px;
}

.rd-16 {
    border-radius: 16px !important;
}

.Pop-Bold {
    font-family: 'Poppins-Bold';
}

.Pop-Black {
    font-family: 'Poppins-Black';
}

.Pop-ExtraBold {
    font-family: 'Poppins-ExtraBold';
}

.Pop-ExtraLight {
    font-family: 'Poppins-ExtraLight';
}

.Pop-Light {
    font-family: 'Poppins-Light';
}

.Pop-Medium {
    font-family: 'Poppins-Medium';
}

.Pop-Regular {
    font-family: 'Poppins-Regular';
}

.Pop-SemiBold {
    font-family: 'Poppins-SemiBold';
}

.Pop-Thin {
    font-family: 'Poppins-Thin';
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('../assets/font/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Black';
    src: url('../assets/font/Poppins-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url('../assets/font/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-ExtraLight';
    src: url('../assets/font/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('../assets/font/Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../assets/font/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../assets/font/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../assets/font/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Thin';
    src: url('../assets/font/Poppins-Thin.ttf') format('truetype');
}